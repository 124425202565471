import React from "react"
import futuramaGIF from "../gifs/futurama.gif"
import Layout from "./Layout/Layout"
import Divider from "./Divider/Divider"
import "./index.css"

export default function Home() {
  return (
    <Layout>
      <Divider margin="50px" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Welcome to Stackat </h1>
        <img
          style={{ minWidth: "20vw" }}
          src={futuramaGIF}
          alt="Futurama Welcome to the World of Tomorrow"
        />
      </div>
    </Layout>
  )
}
